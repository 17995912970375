import styled from '@emotion/styled'
import { Line } from 'app/components/Common/Line'
import React, { memo } from 'react'
import { Accordion } from 'react-accessible-accordion'

import { Item, Props as ItemProps } from './Item'

export interface Props {
  items: ItemProps[]
  title?: string
}

export const Accordions = memo(function Accordions({ items, title }: Props) {
  if (!items) {
    return null
  }

  return (
    <Container>
      {title ? (
        <Title>
          <Line />
          {title}
        </Title>
      ) : null}

      <AccordionsWrap allowZeroExpanded>
        {items.map((item, index) => (
          <Item key={index} {...item} />
        ))}
      </AccordionsWrap>
    </Container>
  )
})

const Container = styled.section`
  margin: 12.5rem 0;
  padding: 0 10vw;

  @media (max-width: 1023px) {
    margin-top: 2.5rem;
    margin-bottom: 8.5rem;
    padding: 0 1.3125rem;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3.125rem;
  line-height: 3.75rem;

  @media (max-width: 1023px) {
    font-size: 2.1875rem;
    line-height: 2.8125rem;
  }
`

const AccordionsWrap = styled(Accordion)`
  margin-top: 3.75rem;

  > div {
    &.expanded {
      background: ${({ theme }) => theme.colors.variants.neutralLight3};
      border-radius: 2.5rem;
      margin-top: 1rem;
      padding-top: 1.875rem;
      transition: background 0.4s ease-in-out;

      .accordion-title {
        text-decoration: underline;
      }
    }
  }
`
