import styled from '@emotion/styled'
import { Minus, Plus } from 'app/components/Icons'
import { AnimatePresence, motion } from 'framer-motion'
import React, { memo } from 'react'
import {
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemState,
} from 'react-accessible-accordion'

export interface Props {
  description?: string
  isExpanded?: boolean
  title?: string
}

export const Item = memo(function Item({ description, title }: Props) {
  if (!title) {
    return null
  }

  return (
    <Container activeClassName="expanded">
      <Head>
        <Title className="accordion-title">
          {title}
          <Button>
            <State>{({ expanded }) => (expanded ? <Minus /> : <Plus />)}</State>
          </Button>
        </Title>
      </Head>
      {description ? (
        <Panel>
          <State>
            {({ expanded }) => (
              <AnimatePresence>
                {expanded && (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{
                      opacity: 1,
                      height: 'auto',
                    }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    <Description
                      dangerouslySetInnerHTML={{ __html: description }}
                    />
                  </motion.div>
                )}
              </AnimatePresence>
            )}
          </State>
        </Panel>
      ) : null}
    </Container>
  )
})

const Container = styled(AccordionItem)`
  border: 0.625rem solid ${({ theme }) => theme.colors.variants.neutralLight3};
  border-radius: 6.25rem;
  margin-top: 1rem;
`

const Head = styled(AccordionItemHeading)`
  cursor: pointer;
`

const Title = styled(AccordionItemButton)`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.3125rem;
  padding: 1.125rem 2.875rem;

  @media (max-width: 1023px) {
    padding-right: 1.875rem;
    padding-left: 1.875rem;
  }
`

const Button = styled.div`
  margin-left: auto;
  transform: translateY(0.1875rem);

  svg {
    width: auto;
    height: 1.25rem;
    fill: none;
    stroke: ${({ theme }) => theme.colors.variants.neutralDark2};
    stroke-width: 3;
  }
`

const State = styled(AccordionItemState)``

const Panel = styled(AccordionItemPanel)`
  display: block !important;
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  line-height: 1.5rem;
  overflow: hidden;
  padding: 0 2.875rem;

  @media (max-width: 1023px) {
    padding-right: 1.875rem;
    padding-left: 1.875rem;
  }
`

const Description = styled.div`
  padding: 0.75rem 0 2.1875rem;

  p {
    margin-block-end: 1em;
  }

  ul {
    margin: 1em 0;
    padding: inherit;
    li {
      padding-left: 0.875rem;
      position: relative;
      &:before {
        content: '';
        width: 0.3125rem;
        height: 0.3125rem;
        background: ${({ theme }) => theme.colors.variants.neutralDark4};
        border-radius: 50%;
        position: absolute;
        top: 0.8125rem;
        left: 0;
      }
    }
  }
`
